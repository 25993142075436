import Glide, { Controls, Images, Keyboard, Swipe } from '@glidejs/glide/dist/glide.modular.esm'

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-gallery').forEach(galleryElement => {
        const glide = galleryElement.glide = new Glide(galleryElement, {
            rewind: false,
            animationTimingFunc: 'ease-in-out'
        })
        const items = galleryElement.querySelectorAll('.js-gallery-slide').length
        glide.on(['build.after', 'run.after'], ev => {
            if (glide.index === 0) {
                galleryElement.querySelector('[data-glide-dir="<"]').classList.add('is-disabled')
            } else {
                galleryElement.querySelector('[data-glide-dir="<"]').classList.remove('is-disabled')
            }

            if (glide.index >= items - 1) {
                galleryElement.querySelector('[data-glide-dir=">"]').classList.add('is-disabled')
            } else {
                galleryElement.querySelector('[data-glide-dir=">"]').classList.remove('is-disabled')
            }
        })
        glide.mount({ Controls, Images, Keyboard, Swipe })
    })
})

window.addEventListener('load', () => {
    document.querySelectorAll('.js-gallery').forEach(galleryElement => {
        if (galleryElement.glide) {
            galleryElement.glide.mount()
        }
    })
})
