import { DynamicField } from '../../molecules/form/dynamicField'

document.addEventListener('DOMContentLoaded', () => {
    const productForm = document.querySelector('.js-product-form')
    if (productForm) {
        productForm.querySelectorAll('.js-dynamic-field').forEach(field => {
            // eslint-disable-next-line no-new
            new DynamicField(field)
        })
    }
})
