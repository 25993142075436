import { Collapse } from '../../molecules/collapse/collapse'

class ProfileView {
    constructor(domElement) {
        /**
         * @type {HTMLElement}
         */
        this.element = domElement

        this.initializeElements()
        this.openStallIfNecessary()
    }

    initializeElements() {
        this.element.querySelectorAll('.js-collapse').forEach(collapseElement => {
            collapseElement.collapse = new Collapse(collapseElement, {
                collapsedHeight: element => {
                    return getComputedStyle(element.querySelector(':nth-child(2)')).getPropertyValue('height')
                }
            })
        })
    }

    /**
     * This expands the stall, that is provided in the URL anchor
     */
    openStallIfNecessary() {
        const anchor = window.location.hash
        if (anchor.length > 0) {
            const stall = this.element.querySelector(`[data-stall-identifier="${anchor.substr(1)}"]`)
            if (stall && stall.collapse) {
                stall.classList.add('is-pre-selected')
                stall.collapse.expand(true)
            }
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const profileElement = document.querySelector('.js-producer-profile-view')
    if (profileElement) {
        // eslint-disable-next-line no-new
        new ProfileView(profileElement)
    }
})
