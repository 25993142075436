import { ClassicEditor } from '../../molecules/editor/editor'
import { MultiButton, CheckButton } from '../../atoms/button/button'
import { DynamicField } from '../../molecules/form/dynamicField'

class ProfileView {
    /**
     *
     * @param element {HTMLElement | Element}
     */
    constructor(element) {
        /**
         * @type {HTMLElement | Element}
         */
        this.element = element
        this.initializeAddDescriptionButton()
        this.initializeDynamicFields()
        this.element.querySelectorAll('.js-editor').forEach(field => {
            this.initializeEditor(field)
        })
    }

    initializeAddDescriptionButton() {
        const addDescriptionButton = this.element.querySelector('.js-description-add')
        const descriptionFieldTemplateElement = this.element.querySelector('.js-template[data-field-name="description"]')
        let descriptionFieldTemplate = null
        if (descriptionFieldTemplateElement) {
            descriptionFieldTemplate = descriptionFieldTemplateElement.innerHTML
            descriptionFieldTemplateElement.remove()
        }
        if (addDescriptionButton && descriptionFieldTemplate) {
            this.addDescriptionMultiButton = new MultiButton(addDescriptionButton)
            this.addDescriptionMultiButton.addEventListener('optionSelected', ev => {
                const option = ev.detail.option
                let template = descriptionFieldTemplate
                template = template.replace(/{{ label }}/g, option.innerText)
                template = template.replace(/{{ value }}/g, option.dataset.value)
                template = template.replace(/{{ language }}/g, option.dataset.language)

                const temp = document.createElement('div')
                temp.innerHTML = template
                temp.querySelectorAll('.js-editor-template').forEach(element => {
                    element.classList.replace('js-editor-template', 'js-editor')
                })
                const insertedElement = addDescriptionButton.parentElement.parentElement.insertBefore(temp.firstElementChild, addDescriptionButton.parentNode)
                const formField = insertedElement.querySelector('.js-editor')
                this.initializeEditor(formField)
                this.addDescriptionMultiButton.disableOption(`[data-value="${option.dataset.value}"]`)
                this.initializeDeleteButton(insertedElement.querySelector(`.js-form-delete[data-for="${formField.getAttribute('id')}"]`))
            })

            // Initialize editors
            addDescriptionButton.querySelectorAll('.js-option').forEach(option => {
                const formField = document.querySelector(`.js-editor[name="description[${option.dataset.value}]"]`)
                if (formField) {
                    this.addDescriptionMultiButton.disableOption(`[data-value="${option.dataset.value}"]`)
                    this.initializeDeleteButton(document.querySelector(`.js-form-delete[data-for="${formField.getAttribute('id')}"]`))
                }
            })
        }
    }

    initializeEditor(element) {
        ClassicEditor.create(element).catch(error => {
            console.error(error)
        })
    }

    initializeDeleteButton(button) {
        button.addEventListener('click', ev => {
            const formElement = document.querySelector(`#${ev.currentTarget.dataset.for}`)
            const matches = formElement.getAttribute('name').match(/description\[(.*?)]/)
            formElement.parentElement.remove()
            this.addDescriptionMultiButton.enableOption(`[data-value="${matches[1]}"]`)
        })
    }

    initializeDynamicFields() {
        this.element.querySelectorAll('.js-dynamic-field').forEach(field => {
            const dynamicField = new DynamicField(field)
            if (field.dataset.fieldName === 'categories') {
                const initializeProductRow = row => {
                    const buttonElement = row.querySelector('.js-certificates')
                    const certificatesButton = new CheckButton(buttonElement)
                    buttonElement.dataset.originalLabel = certificatesButton.getButtonText()
                    const updateButtonText = () => {
                        const labels = certificatesButton.getLabels(true)
                        if (labels.length === 0) {
                            certificatesButton.setButtonText(buttonElement.dataset.originalLabel)
                        } else {
                            certificatesButton.setButtonText(labels.join(', '))
                        }
                    }
                    certificatesButton.addEventListener('optionSelected', () => {
                        updateButtonText()
                    })
                    updateButtonText()
                    row.querySelector('.js-delete-button').addEventListener('click', () => {
                        row.remove()
                    })
                }
                dynamicField.addEventListener('rowAdded', ev => {
                    initializeProductRow(ev.detail.row)
                })
                dynamicField.getRows().forEach(row => {
                    initializeProductRow(row)
                })
            }
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    Promise.resolve().then(() => {
        const profileViewElement = document.querySelector('.js-profile-view')
        if (profileViewElement) {
            new ProfileView(profileViewElement) // eslint-disable-line no-new
        }
    })
})
