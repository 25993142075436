import { SearchForm } from './SearchForm'

class MainMenu {
    /**
     * @param domElement {HTMLElement|Element}
     */
    constructor(domElement) {
        /**
         * @private
         * @type {HTMLElement|Element}
         */
        this.element = domElement

        this.initializeElements()
        this.initializeEvents()
    }

    initializeElements() {
        this.openButton = this.element.querySelector('.js-menu-open')
        this.closeButton = this.element.querySelector('.js-menu-close')

        const searchFormElement = this.element.querySelector('.js-menu-search-form')
        if (searchFormElement) {
            this.searchForm = new SearchForm(searchFormElement)
        }
    }

    initializeEvents() {
        this.openButton.addEventListener('click', () => {
            this.open()
        })

        this.closeButton.addEventListener('click', () => {
            this.close()
        })

        this.element.addEventListener('transitionend', this.onTransitionEnd.bind(this))

        this.element.addEventListener('click', ev => {
            if (ev.currentTarget === ev.target) {
                this.close()
            }
        })
    }

    isOpen() {
        return this.element.classList.contains('is-open')
    }

    open() {
        this.element.classList.add('is-open')
    }

    close() {
        this.element.classList.add('is-animating')
        this.element.classList.remove('is-open')
    }

    onTransitionEnd(ev) {
        this.element.classList.remove('is-animating')
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const menuElement = document.querySelector('.js-menu-main')
    if (menuElement) {
        // eslint-disable-next-line no-new
        new MainMenu(menuElement)
    }
})
