const merge = require('lodash.merge')

/**
 * @implements EventTarget
 */
class Dialog {
    /**
     *
     * @param element {HTMLElement|Element}
     * @param options {DialogOptions | {}}
     */
    constructor(element, options = {}) {
        /**
         * @private
         * @type {HTMLElement|Element}
         */
        this.element = element

        /**
         * @private
         * @type DialogOptions
         */
        this.options = merge({
            elements: {
                ok: '.js-ok',
                dismiss: '.js-dismiss',
                message: '.js-message'
            },
            state: {
                open: 'is-open',
                closing: 'is-closing'
            },
            transition: true,
            autoDismissal: true
        }, options)

        this.initializeElements()
        this.initializeEvents()
    }

    /**
     * @private
     */
    initializeElements() {
        /**
         * @private
         * @type {HTMLElement | Element}
         */
        this.okButton = this.element.querySelector(this.options.elements.ok)
        /**
         * @private
         * @type {NodeList | NodeListOf<HTMLElement | Element> | HTMLElement | Element}
         */
        this.dismissButtons = this.element.querySelectorAll(this.options.elements.dismiss)
        /**
         * @private
         * @type {HTMLElement | Element}
         */
        this.messageElement = this.element.querySelector(this.options.elements.message)
    }

    /**
     * @private
     */
    initializeEvents() {
        /**
         * @private
         * @type {EventTarget}
         */
        this.eventTarget = new EventTarget()
        if (this.okButton) {
            this.okButton.addEventListener('click', () => {
                this.eventTarget.dispatchEvent(new CustomEvent('click', { detail: { button: 'ok' } }))
            })
        }
        if (this.dismissButtons) {
            this.dismissButtons.forEach(dismissButton => {
                dismissButton.addEventListener('click', () => {
                    if (this.options.autoDismissal) {
                        this.close()
                    }
                    this.eventTarget.dispatchEvent(new CustomEvent('click', { detail: { button: 'dismiss' } }))
                })
            })
        }

        this.element.addEventListener('transitionend', this.onTransitionEnd.bind(this))
    }

    open() {
        requestAnimationFrame(() => {
            this.element.classList.add(this.options.state.open)
        })
    }

    close(disableTransition = false) {
        if (this.isOpen()) {
            if (!disableTransition && this.options.transition) {
                requestAnimationFrame(() => {
                    this.element.classList.add(this.options.state.closing)
                    this.element.classList.remove(this.options.state.open)
                })
            } else {
                this.element.classList.remove(this.options.state.open)
            }
        }
    }

    isOpen() {
        return this.element.classList.contains(this.options.state.open)
    }

    onTransitionEnd() {
        if (!this.isOpen()) {
            this.element.classList.remove(this.options.state.closing)
        }
    }

    /**
     * @param message {string}
     */
    setMessage(message) {
        if (this.messageElement) {
            this.messageElement.innerText = message
        }
    }

    addEventListener(type, listener, options = false) {
        return this.eventTarget.addEventListener(type, listener, options)
    }

    dispatchEvent(event) {
        return this.eventTarget.dispatchEvent(event)
    }

    removeEventListener(type, callback, options = false) {
        return this.eventTarget.removeEventListener(type, callback, options)
    }
}

export { Dialog }
