import { Sortable } from 'sortablejs/modular/sortable.core.esm'

const merge = require('lodash.merge')

/**
 * @implements EventTarget
 */
class DynamicField {
    /**
     * @param {HTMLElement | Element} domElement
     * @param {DynamicFieldOptions} options
     */
    constructor(domElement, options) {
        /**
         * @private
         * @type {HTMLElement|Element}
         */
        this.element = domElement

        /**
         * @private
         * @type DynamicFieldOptions
         */
        this.options = merge({
            elements: {
                add: '.js-add-button',
                delete: '.js-delete-button',
                template: '.js-template',
                row: '.js-row'
            }
        }, options)

        /**
         * @type {string}
         */
        this.fieldName = this.element.dataset.fieldName
        this.maxRows = this.element.dataset.maxRows
        this.rowNumberPlaceholder = this.generateRowNumberPlaceholder()

        this.initializeElements()
        this.initializeEvents()
        this.updateAddButtonState()
    }

    initializeElements() {
        /**
         * @private
         * @type {HTMLElement | Element}
         */
        this.addButtons = this.element.querySelectorAll(this.options.elements.add)

        const templateElement = this.element.querySelector(this.options.elements.template)
        /**
         * @private
         * @type {string}
         */
        this.template = templateElement.innerHTML
        templateElement.remove()

        this.element.querySelectorAll(this.options.elements.row).forEach(row => {
            this.initializeRow(row)
        })

        const useHandle = templateElement.querySelectorAll(`${this.options.elements.row} .js-sortable-handle`).length > 0
        Sortable.create(this.element, {
            handle: useHandle ? '.js-sortable-handle' : null
        })
    }

    initializeEvents() {
        /**
         * @private
         * @type {EventTarget}
         */
        this.eventTarget = new EventTarget()
        if (this.addButtons) {
            this.addButtons.forEach(button => {
                button.addEventListener('click', () => {
                    // Only add row, if the button is not disabled
                    if (!button.classList.contains('is-disabled') && !button.getAttribute('disabled')) {
                        this.addRow(button)
                    }
                })
            })
        }
    }

    initializeRow(row) {
        const deleteButton = row.querySelector(this.options.elements.delete)
        if (deleteButton) {
            deleteButton.addEventListener('click', () => {
                this.deleteRow(row)
            })
        }
    }

    generateRowNumberPlaceholder() {
        return '__' + this.fieldName.replace(/-([a-z])/, (match, letter) => {
            return letter.toUpperCase()
        }) + 'Number__'
    }

    addRow(button) {
        let template = this.template
        let highestRowNumber = -1
        const fieldNamePattern = new RegExp(`\\[?${this.fieldName}]?\\[(\\d+)]`)
        this.element.querySelectorAll(`:scope > ${this.options.elements.row} [name*="[${this.fieldName}]"]`).forEach(formField => {
            const matches = fieldNamePattern.exec(formField.getAttribute('name'))
            if (matches && matches.length > 1) {
                highestRowNumber = Math.max(highestRowNumber, parseInt(matches[1]))
            }
        })
        template = template.replace(new RegExp(this.rowNumberPlaceholder, 'g'), String(highestRowNumber + 1))
        const tmp = document.createElement('div')
        tmp.innerHTML = template
        /** @type {HTMLElement | Element } addedRow **/
        const addedRow = button.parentElement.parentElement.insertBefore(tmp.firstElementChild, button.parentElement)
        this.initializeRow(addedRow)
        this.dispatchEvent(new CustomEvent('rowAdded', { detail: { row: addedRow, button: button } }))
        this.updateAddButtonState()
    }

    /**
     * @param {HTMLElement | Element} row
     */
    deleteRow(row) {
        row.remove()
        this.updateAddButtonState()
    }

    updateAddButtonState() {
        // If we have out own add button, handle its state
        if (this.maxRows && this.element.querySelectorAll(`:scope > ${this.options.elements.row}`).length >= this.maxRows) {
            this.addButtons.forEach(button => {
                button.classList.add('is-disabled')
            })
        } else {
            this.addButtons.forEach(button => {
                button.classList.remove('is-disabled')
            })
        }
    }

    updateNumbering() {
        const fieldNamePattern = new RegExp(`\\[?${this.fieldName}]\\[\\d+]`)
        this.element.querySelectorAll(`:scope > ${this.options.elements.row}`).forEach((row, index) => {
            row.querySelectorAll(`[name*="[${this.fieldName}]"]`).forEach(formField => {
                formField.setAttribute('name', formField.getAttribute('name').replace(fieldNamePattern, `[${this.fieldName}][${index}]`))
            })
        })
    }

    addEventListener(type, listener, options = false) {
        return this.eventTarget.addEventListener(type, listener, options)
    }

    dispatchEvent(event) {
        return this.eventTarget.dispatchEvent(event)
    }

    removeEventListener(type, callback, options = false) {
        return this.eventTarget.removeEventListener(type, callback, options)
    }

    /**
     * @returns {NodeListOf<HTMLElement> | NodeListOf<Element> | HTMLElement[] | Element[]}
     */
    getRows() {
        return this.element.querySelectorAll(this.options.elements.row)
    }
}

export { DynamicField }
