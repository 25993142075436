class Tabs {
    /**
     *
     * @param element {HTMLElement | Element}
     */
    constructor(element) {
        this.root = element

        this.initializeElements()
        this.initializeEvents()

        this.activateTab(this.tabs.item(0))
        this.root.classList.add('is-animated')
    }

    initializeElements() {
        this.panels = this.root.querySelectorAll('.js-tabs-panel')
        this.tabs = this.root.querySelectorAll('.js-tabs-item')
    }

    initializeEvents() {
        this.tabs.forEach(tabItem => {
            tabItem.addEventListener('click', ev => {
                ev.preventDefault()
                this.activateTab(tabItem)
            })
        })
    }

    /**
     *
     * @param tab {HTMLElement | Element}
     */
    activateTab(tab) {
        this.tabs.forEach(tabs => {
            tabs.classList.remove('is-active')
        })
        this.panels.forEach(panel => {
            panel.classList.remove('is-active')
        })
        tab.classList.add('is-active')
        this.root.querySelector(tab.dataset.target).classList.add('is-active')
    }
}

document.querySelectorAll('.js-tabs').forEach(tabsElement => {
    tabsElement.tabs = new Tabs(tabsElement)
})
