/* global google */
import MarkerClusterer from '@googlemaps/markerclustererplus'
import MarkerWithLabel from '@google/markerwithlabel'

class MapView {
    /**
     * @param domElement {HTMLElement}
     */
    constructor(domElement) {
        /**
         * @private
         * @type {HTMLElement}
         */
        this.element = domElement

        this.settings = {
            defaultBoundaries: null,
            initialBoundaries: null,
            apiBaseUrl: this.element.dataset.apiBaseUrl,
            resourceBaseUrl: this.element.dataset.resourceBaseUrl,
            profileBaseUrl: this.element.dataset.profileBaseUrl,
            maxAutoZoomLevel: 15
        }

        this.resources = {
            markerIcon: this.settings.resourceBaseUrl + 'img/map_pin.svg',
            markerIconUser: this.settings.resourceBaseUrl + 'img/map_pin_metal.svg'
        }

        this.state = {
            originalHeight: null,
            initialLoad: true
        }

        this.matchMedia = {
            sm: window.matchMedia('(min-width: 575px)')
        }

        /**
         * @type {ProducerInterface[]}
         */
        this.producers = []
        /**
         *
         * @type {google.maps.Marker[]}
         */
        this.markers = []

        this.initializeElements()
        this.initializeEvents()
        this.initializeMap()
        this.loadData()
    }

    initializeElements() {
        this.mapContainer = this.element.querySelector('.js-map-container')
        const defaultBoundaries = JSON.parse(this.mapContainer.dataset.defaultBoundaries)
        this.settings.defaultBoundaries = new google.maps.LatLngBounds(
            new google.maps.LatLng(defaultBoundaries.sw.lat, defaultBoundaries.sw.lng),
            new google.maps.LatLng(defaultBoundaries.ne.lat, defaultBoundaries.ne.lng)
        )
        try {
            const initialBoundaries = JSON.parse(this.mapContainer.dataset.initialBoundaries)
            console.log(initialBoundaries)
            if (initialBoundaries) {
                this.settings.initialBoundaries = new google.maps.LatLngBounds(
                    new google.maps.LatLng(initialBoundaries.sw.lat, initialBoundaries.sw.lng),
                    new google.maps.LatLng(initialBoundaries.ne.lat, initialBoundaries.ne.lng)
                )
            }
        } catch (e) {}

        this.form = this.element.querySelector('.js-search-form')
        this.searchField = this.element.querySelector('.js-map-search')
        this.filterToggle = this.element.querySelector('.js-map-filter')
        this.locationSearch = this.element.querySelector('.js-map-location')
        this.filters = this.element.querySelector('.js-map-filters')
        this.filtersRow = this.element.querySelector('.js-map-filters-row')
        this.filterSecondLevelWrapper = this.element.querySelector('.js-map-filters-second-level-wrapper')

        this.element.querySelectorAll('.js-map-category').forEach(parentCategory => {
            const parentCheckbox = parentCategory.querySelector('input[type="checkbox"]')
            const subTrigger = parentCategory.querySelector('.js-map-category-sub')
            if (subTrigger) {
                const childCheckboxes = this.element.querySelectorAll(`[data-parent-id="${subTrigger.dataset.id}"] .js-map-filter-category`)
                if (parentCheckbox && childCheckboxes.length > 0) {
                    // eslint-disable-next-line no-new
                    new IndeterminateCheckbox(parentCheckbox, childCheckboxes)
                }
            }
        })

        this.filterApply = this.element.querySelector('.js-map-filter-apply')
    }

    initializeEvents() {
        const toggleFilterList = () => {
            this.filters.classList.toggle('is-active')
            if (this.filters.classList.contains('is-active')) {
                checkFilterListHeight()
            } else {
                this.applyFilter()
            }
        }

        const openFilterList = () => {
            this.filters.classList.add('is-active')
        }

        const checkFilterListHeight = () => {
            this.state.originalHeight = window.getComputedStyle(this.filtersRow).getPropertyValue('height')
            this.filtersRow.style.height = this.state.originalHeight
        }
        this.filterToggle.addEventListener('click', toggleFilterList.bind(this))
        this.searchField.addEventListener('focus', openFilterList.bind(this))
        this.searchField.addEventListener('click', openFilterList.bind(this))

        this.element.querySelectorAll('.js-map-category-sub').forEach(button => {
            button.addEventListener('click', () => {
                this.openCategory(button)
            })
        })

        this.element.querySelectorAll('.js-map-category-back').forEach(button => {
            button.addEventListener('click', () => {
                this.closeCategory()
            })
        })

        this.filterApply.addEventListener('click', () => {
            this.filters.classList.remove('is-active')
            this.applyFilter()
        })

        this.filtersRow.addEventListener('transitionend', () => {
            if (!this.filtersRow.classList.contains('is-sub-open')) {
                // Hide all active sub lists
                this.element.querySelectorAll('[data-parent-id].is-active').forEach(subCategoryList => {
                    subCategoryList.classList.remove('is-active')
                })
            }
        })

        this.locationSearch.addEventListener('click', () => {
            this.enableLocationSearch()
        })

        this.oldSearchValue = this.searchField.value
        this.searchTimeout = null
        const searchFieldChange = ev => {
            if (this.searchField.value !== this.oldSearchValue) {
                if (this.searchTimeout) {
                    clearTimeout(this.searchTimeout)
                }
                this.searchTimeout = setTimeout(() => {
                    this.search()
                }, 500)
            }
            this.oldSearchValue = this.searchField.value
        }
        this.searchField.addEventListener('change', searchFieldChange.bind(this))
        this.searchField.addEventListener('keyup', searchFieldChange.bind(this))
        this.searchField.addEventListener('paste', searchFieldChange.bind(this))
        this.searchField.addEventListener('input', searchFieldChange.bind(this))

        this.form.addEventListener('submit', ev => {
            ev.preventDefault()
            this.searchField.blur()
            this.search()
        })
    }

    initializeMap() {
        /**
         * @type {google.maps.Map}
         */
        this.map = new google.maps.Map(this.mapContainer, {
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            fullscreenControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            }
        })
        this.map.addListener('zoom_changed', () => {
            if (this.map.zoomToFitBounds) {
                this.map.zoomToFitBounds = false
                if (this.map.getZoom() > this.settings.maxAutoZoomLevel) {
                    this.map.setZoom(this.settings.maxAutoZoomLevel)
                }
            }
        })
        this.fitBounds(this.settings.defaultBoundaries)
        this.cluster = new MarkerClusterer(this.map, this.markers, {
            averageCenter: true,
            minimumClusterSize: 3,
            clusterClass: 'map__cluster',
            styles: [
                {
                    width: 30,
                    height: 30,
                    className: 'map__cluster--default'
                }
            ],
            ignoreHidden: true
        })

        /**
         * @type {google.maps.InfoWindow}
         */
        this.infoWindow = new google.maps.InfoWindow({
            maxWidth: 400
        })
        this.map.addListener('click', () => {
            this.infoWindow.close()
        })

        /**
         * @type {google.maps.Marker}
         */
        this.userLocationMarker = new google.maps.Marker({
            icon: {
                url: this.resources.markerIconUser,
                size: new google.maps.Size(40, 40),
                scaledSize: new google.maps.Size(40, 40)
            }
        })
    }

    /**
     *
     * @param button {HTMLElement}
     */
    openCategory(button) {
        const subList = this.element.querySelector(`[data-parent-id="${button.dataset.id}"]`)
        subList.classList.add('is-active')
        this.filtersRow.style.height = window.getComputedStyle(this.filterSecondLevelWrapper).getPropertyValue('height')
        this.filtersRow.classList.add('is-sub-open')
    }

    closeCategory() {
        this.filtersRow.classList.remove('is-sub-open')
        this.filtersRow.style.height = this.state.originalHeight
    }

    applyFilter() {
        this.infoWindow.close()
        const certificatesFilter = []
        this.element.querySelectorAll('.js-map-filter-certificate:checked').forEach(checkbox => {
            certificatesFilter.push(checkbox.value)
        })

        const categoriesFilter = []
        this.element.querySelectorAll('.js-map-filter-category:checked').forEach(checkbox => {
            categoriesFilter.push(parseInt(checkbox.value))
        })
        if (certificatesFilter.length > 0 || categoriesFilter.length > 0) {
            this.filterToggle.classList.add('is-active')
            this.cluster.getMarkers().forEach(marker => {
                /** @type {ProducerInterface} */
                const producer = marker.producer
                if (certificatesFilter.indexOf('organic') !== -1) {
                    marker.setVisible(producer.organic)
                }
                if (categoriesFilter.length > 0) {
                    let hasSelectedProduct = false
                    producer.products.forEach(product => {
                        if (categoriesFilter.indexOf(product.id) !== -1) {
                            hasSelectedProduct = true
                        }
                    })
                    marker.setVisible(hasSelectedProduct)
                }
            })
        } else {
            this.filterToggle.classList.remove('is-active')
            this.cluster.getMarkers().forEach(marker => {
                marker.setVisible(true)
            })
        }
        this.cluster.repaint()
        this.zoomToMarkerBounds()
    }

    loadData(url = '/producer/all') {
        this.infoWindow.close()
        if (this.abortController) {
            this.abortController.abort()
        }
        this.abortController = new AbortController()
        this.searchField.parentElement.classList.add('is-loading')
        fetch(this.settings.apiBaseUrl + url, {
            method: 'get',
            credentials: 'include',
            signal: this.abortController.signal
        }).then(response => {
            if (response.ok) {
                return response.json().then(producers => {
                    this.producers = producers
                    this.searchField.parentElement.classList.remove('is-loading')
                    this.updateMarkers(true)
                })
            } else {
                this.searchField.parentElement.classList.remove('is-loading')
            }
        }).catch(e => {
            if (!(e instanceof DOMException) || e.name !== 'AbortError') {
                this.searchField.parentElement.classList.remove('is-loading')
                throw e
            }
        })
    }

    search() {
        this.filters.classList.remove('is-active')
        if (this.searchField.value.length > 1) {
            this.loadData('/producer/search?q=' + encodeURIComponent(this.searchField.value))
        } else {
            this.loadData()
        }
    }

    updateMarkers(reset = false) {
        if (reset) {
            this.cluster.removeMarkers(this.markers)
            this.markers = []
            this.producers.forEach(producer => {
                producer.stalls.forEach(stall => {
                    const marker = new MarkerWithLabel({
                        position: new google.maps.LatLng(stall.latitude, stall.longitude),
                        icon: {
                            url: this.resources.markerIcon + (producer.organic ? '?organic' : ''),
                            size: new google.maps.Size(32, 32),
                            scaledSize: new google.maps.Size(32, 32)
                        },
                        labelAnchor: new google.maps.Point(-4, 16),
                        labelVisible: producer.organic,
                        labelClass: 'map__marker-label' + (producer.organic ? ' map__marker-label--organic' : '')
                    })
                    marker.producer = producer
                    marker.stall = stall
                    marker.addListener('click', () => {
                        this.showInfo(marker)
                    })
                    this.markers.push(marker)
                })
            })
        }
        this.cluster.addMarkers(this.markers)
        this.cluster.repaint()
        this.zoomToMarkerBounds()
    }

    getMarkerBounds() {
        const bounds = new google.maps.LatLngBounds()
        this.markers.forEach(marker => {
            if (marker.getVisible()) {
                bounds.extend(marker.getPosition())
            }
        })

        return bounds
    }

    /**
     * Zooms and pans the map to fit the given boundaries.
     * Setting the `zoomToFitBounds = true` ensures, that we
     * do not get a too close zoom level.
     *
     * @param bounds
     */
    fitBounds(bounds) {
        this.map.zoomToFitBounds = true
        this.map.fitBounds(bounds)
    }

    zoomToMarkerBounds() {
        /** @type google.maps.LatLngBounds bounds */
        let bounds = null

        if (this.state.initialLoad) {
            this.state.initialLoad = false

            if (this.settings.initialBoundaries) {
                bounds = this.settings.initialBoundaries
            }
        }
        if (!bounds) {
            bounds = this.getMarkerBounds()
        }
        if (!bounds || bounds.isEmpty()) {
            this.fitBounds(this.settings.defaultBoundaries)
        } else {
            this.fitBounds(bounds)
        }
    }

    enableLocationSearch() {
        navigator.geolocation.getCurrentPosition((location) => {
            this.locationSearch.classList.add('is-active')
            let bounds = this.getMarkerBounds()
            const userLocation = new google.maps.LatLng(location.coords.latitude, location.coords.longitude)
            if (bounds.contains(userLocation)) {
                // If the map area covered by currently shown markers already include the users location, zoom into to about 30km radius around the users location
                const degreeMargin = 30 / 111.1
                const southWest = new google.maps.LatLng(location.coords.latitude + degreeMargin, location.coords.longitude - degreeMargin)
                const northEast = new google.maps.LatLng(location.coords.latitude - degreeMargin, location.coords.longitude + degreeMargin)
                bounds = new google.maps.LatLngBounds(southWest, northEast)
            } else {
                // If the users location is outside the currently shown markers map area, position and zoom the map to also show the users location
                bounds.extend(userLocation)
            }
            this.userLocationMarker.setPosition(userLocation)
            this.userLocationMarker.setMap(this.map)
            this.fitBounds(bounds)
        }, (error) => {
            console.error(error)
        })
    }

    disableLocationSearch() {
        this.userLocationMarker.setMap(null)
        this.locationSearch.classList.remove('is-active')
    }

    /**
     * @param marker {google.maps.Marker}
     */
    showInfo(marker) {
        /** @type {ProducerInterface} */
        const producer = marker.producer
        /** @type {StallInterface} */
        const stall = marker.stall
        const hash = producer.stalls.length > 1 ? '#' + stall.identifier : ''
        let content = `
<div class="info-window">
<h1 class="info-window__header">${stall.combinedName}</h1>
<div class="info-window__content">
    <address class="info-window__address">
        ${stall.address}<br />
        ${stall.postalCode} ${stall.city}
    </address>
    <a class="button button--primary button--next info-window__link" href="${this.settings.profileBaseUrl}${producer.slug}${hash}">Til profilen</a>
</div>
`
        const products = []
        producer.products.forEach(product => {
            if (product.image && product.image.length > 0) {
                const productHtml = `
<div class="info-window__product${product.organic ? ' info-window__product--organic' : ''}">
    <img width="48" height="48" class="info-window__product-image" alt="${product.name}" title="${product.name}" src="${product.image}" />
</div>
`
                products.push(productHtml)
            }
        })
        if (products.length > 0) {
            content += `<div class="info-window__products">${products.join('')}</div>`
        }
        content += '</div>'
        this.infoWindow.close()
        this.infoWindow.setContent(content)
        this.infoWindow.open(this.map, marker)
    }
}

class IndeterminateCheckbox {
    constructor(parent, children) {
        /**
         * @type {HTMLElement}
         */
        this.parent = parent
        /**
         * @type {HTMLElement[]|NodeList<HTMLElement>}
         */
        this.children = children

        this.initializeEvents()
    }

    initializeEvents() {
        this.parent.addEventListener('change', ev => {
            if (ev.currentTarget.checked) {
                this.setChildrenChecked(true)
            } else {
                this.setChildrenChecked(false)
            }
        })

        this.children.forEach(child => {
            child.addEventListener('change', () => {
                this.evaluateChildrenForState()
            })
        })
    }

    /**
     * @param checked {boolean}
     */
    setChildrenChecked(checked) {
        this.children.forEach(child => {
            child.checked = checked
        })
    }

    evaluateChildrenForState() {
        let atLeastOneChecked = false
        let allChecked = true

        this.children.forEach(child => {
            if (child.checked) {
                atLeastOneChecked = true
            } else {
                allChecked = false
            }
        })

        if (!allChecked) {
            this.parent.checked = false
            this.parent.indeterminate = atLeastOneChecked
        } else {
            this.parent.indeterminate = false
            this.parent.checked = true
        }
    }
}

export { MapView }
