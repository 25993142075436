import { CookieNotice } from '../../molecules/cookie-notice/cookie-notice'
import { GoogleAnalytics } from '../../molecules/cookie-notice/google-analytics'

class Content {
    constructor() {
        this.initializeCookieNotice()
        this.initializeGoogleAnalytics()
    }

    /**
     * @private
     */
    initializeCookieNotice() {
        const cookieNoticeElement = document.querySelector('.js-cookie-notice')
        if (cookieNoticeElement) {
            // eslint-disable-next-line no-new
            this.cookieNotice = new CookieNotice(cookieNoticeElement)
            this.cookieNotice.addEventListener('consentChanged', ev => {
                this.googleAnalytics.updateConsent(ev.detail)
            })
        }
    }

    /**
     * @private
     */
    initializeGoogleAnalytics() {
        // eslint-disable-next-line no-new
        this.googleAnalytics = new GoogleAnalytics()
    }
}

document.addEventListener('DOMContentLoaded', () => {
    // eslint-disable-next-line no-new
    new Content()
})
