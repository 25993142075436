import { ConsentCookie } from './consent-cookie'

class CookieNotice {
    /**
     * @param {HTMLElement | Element} domElement
     */
    constructor(domElement) {
        /**
         * @type {HTMLElement | Element}
         * @private
         */
        this.element = domElement

        /**
         * @private
         * @type {EventTarget}
         */
        this.eventTarget = new EventTarget()

        /**
         * @type {ConsentCookie}
         * @private
         */
        this.consentCookie = new ConsentCookie()

        this.initializeElements()
        this.initializeEvents()
        this.updateActiveButton()

        if (this.consentCookie.isConsentUnclear()) {
            this.open()
        }
    }

    /**
     * Initialize internal variables for DOM elements
     *
     * @private
     */
    initializeElements() {
        /**
         * @type {HTMLElement | Element}
         * @private
         */
        this.buttons = this.element.querySelectorAll('.js-button')
        this.buttons.forEach(button => {
            button.originalText = button.innerHTML
        })

        this.handle = this.element.querySelector('.js-handle')
    }

    /**
     * Initialize events on internal DOM elements
     *
     * @private
     */
    initializeEvents() {
        this.handle.addEventListener('click', ev => {
            if (this.isOpen()) {
                this.close()
            } else {
                this.open()
            }
        })

        this.buttons.forEach(button => {
            button.addEventListener('click', () => {
                const consent = {
                    statistic: false,
                    marketing: false
                }
                if (button.dataset.accept === 'all') {
                    consent.statistic = true
                    consent.marketing = true
                } else if (button.dataset.accept === 'statistic') {
                    consent.statistic = true
                }
                this.consentCookie.setContent(consent)
                this.dispatchEvent(new CustomEvent('consentChanged', { detail: consent }))
                this.updateActiveButton(button)
                setTimeout(() => {
                    this.close()
                }, 500)
            })
        })

        this.element.addEventListener('transitionend', () => {
            this.element.classList.remove('is-in-transition')
        })

        window.addEventListener('load', () => {
            this.element.classList.add('is-animated')
        })
    }

    /**
     * Update the active state of the buttons depending on the consent state
     *
     * @private
     */
    updateActiveButton(clickedButton) {
        if (!clickedButton) {
            if (this.consentCookie.isConsentGiven('marketing')) {
                clickedButton = this.element.querySelector('.js-button[data-accept="all"]')
            } else if (this.consentCookie.isConsentGiven('statistic')) {
                clickedButton = this.element.querySelector('.js-button[data-accept="statistic"]')
            } else if (!this.consentCookie.isConsentUnclear()) {
                clickedButton = this.element.querySelector('.js-button:not([data-accept])')
            }
        }
        this.buttons.forEach(button => {
            if (clickedButton === button) {
                button.innerHTML = `&checkmark; ${button.originalText}`
            } else {
                button.innerHTML = button.originalText
            }
        })
    }

    open() {
        this.element.classList.add('is-open', 'is-in-transition')
    }

    close() {
        this.element.classList.add('is-in-transition')
        this.element.classList.remove('is-open')
    }

    isOpen() {
        return this.element.classList.contains('is-open')
    }

    /**
     * @param type {string}
     * @param listener {function}
     * @param options {boolean|EventListenerOptions}
     */
    addEventListener(type, listener, options = false) {
        return this.eventTarget.addEventListener(type, listener, options)
    }

    /**
     * @param type {string}
     * @param listener {function}
     * @param options {boolean|EventListenerOptions}
     */
    removeEventListener(type, listener, options = false) {
        this.eventTarget.removeEventListener(type, listener, options)
    }

    /**
     * @param event {Event}
     *
     * @returns {boolean}
     */
    dispatchEvent(event) {
        return this.eventTarget.dispatchEvent(event)
    }
}

export { CookieNotice }
