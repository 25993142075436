class MultiButton {
    /**
     * @param domElement {HTMLElement|Element}
     * @param options
     */
    constructor(domElement, options = {}) {
        this.eventTarget = new EventTarget()
        /**
         * @type {HTMLElement|Element}
         */
        this.element = domElement

        // Set default options
        this.options = {
            closeOnSelect: true
        }

        // Merge given options
        for (const name in this.options) {
            if (Object.hasOwnProperty.call(this.options, name) && Object.hasOwnProperty.call(options, name)) {
                this.options[name] = options[name]
            }
        }

        this.initializeElements()
        this.initializeEvents()
    }

    initializeElements() {
        /**
         * @type {HTMLElement | Element }
         */
        this.button = this.element.querySelector('.js-button')
    }

    initializeEvents() {
        if (this.button) {
            this.button.addEventListener('click', () => {
                if (this.isOpen()) {
                    this.close()
                } else {
                    this.open()
                }
            })
        } else {
            console.error('There seems to be no `.js-button` element inside your multi button HTML structure.')
        }

        this.element.querySelectorAll('.js-option').forEach(option => {
            option.addEventListener('click', ev => {
                const clickedOption = ev.currentTarget
                this.onOptionSelect(clickedOption)
            })
        })

        /* Close on leave */
        this.closeTimeout = null
        this.element.addEventListener('mouseleave', this.onLeave.bind(this))
        this.element.addEventListener('mouseenter', this.onEnter.bind(this))
    }

    onOptionSelect(selectedOption) {
        this.dispatchEvent(new CustomEvent('optionSelected', { detail: { option: selectedOption } }))
        if ((this.options.closeOnSelect)) {
            this.close()
        }
    }

    onLeave() {
        if (this.isOpen()) {
            this.closeTimeout = setTimeout(() => {
                this.close()
            }, 300)
        }
    }

    onEnter() {
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout)
        }
    }

    open() {
        this.element.classList.add('is-open')
    }

    close() {
        this.element.classList.remove('is-open')
    }

    isOpen() {
        return this.element.classList.contains('is-open')
    }

    disableOption(selector) {
        this.element.querySelectorAll('.js-option').forEach(option => {
            if (option.matches(selector)) {
                option.classList.add('is-disabled')
            }
        })

        this.updateButtonState()
    }

    enableOption(selector) {
        this.element.querySelectorAll('.js-option').forEach(option => {
            if (option.matches(selector)) {
                option.classList.remove('is-disabled')
            }
        })

        this.updateButtonState()
    }

    updateButtonState() {
        if (this.element.querySelectorAll('.js-option:not(.is-disabled)').length === 0) {
            this.element.classList.add('is-disabled')
        } else {
            this.element.classList.remove('is-disabled')
        }
    }

    addEventListener(type, listener, options = false) {
        return this.eventTarget.addEventListener(type, listener, options)
    }

    removeEventListener(type, listener, options = false) {
        return this.eventTarget.addEventListener(type, listener, options)
    }

    dispatchEvent(event) {
        return this.eventTarget.dispatchEvent(event)
    }
}

class CheckButton extends MultiButton {
    constructor(domElement, options = {}) {
        super(domElement, { closeOnSelect: false })

        // Merge given options
        for (const name in this.options) {
            if (Object.hasOwnProperty.call(this.options, name) && Object.hasOwnProperty.call(options, name)) {
                this.options = options[name]
            }
        }
    }

    initializeEvents() {
        super.initializeEvents()

        this.element.querySelectorAll('input[type="checkbox"]').forEach(option => {
            option.addEventListener('change', ev => {
                const clickedOption = ev.currentTarget
                this.onOptionSelect(clickedOption)
            })
        })
    }

    getButtonText() {
        return this.button.innerText
    }

    setButtonText(text) {
        this.button.innerText = text
    }

    getValue() {
        return this.getValues().join(this.options.valueSeparator)
    }

    getValues() {
        const values = []
        this.element.querySelectorAll('input[type="checkbox"]').forEach(option => {
            if (option.checked) {
                values.push(option.value)
            }
        })

        return values
    }

    getLabels(onlySelected = false) {
        const labels = []
        this.element.querySelectorAll('label').forEach(label => {
            if (!onlySelected || label.matches(':checked + :scope')) {
                labels.push(label.innerText)
            }
        })

        return labels
    }
}

export { MultiButton, CheckButton }
