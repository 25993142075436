class SearchForm {
    /**
     * @param form {Element | HTMLElement | HTMLFormElement}
     */
    constructor(form) {
        this.form = form

        this.initializeElements()
        this.initializeEvents()
    }

    initializeElements() {
        /**
         * @type {HTMLInputElement}
         */
        this.input = this.form.querySelector('input')
        /**
         *
         * @type {HTMLButtonElement}
         */
        this.button = this.form.querySelector('.js-menu-search-button')
    }

    initializeEvents() {
        this.button.addEventListener('click', ev => {
            if (!this.isOpen()) {
                this.open()
            } else if (this.isOpen() && this.isEmpty()) {
                this.close()
            } else {
                if (this.form.requestSubmit) {
                    this.form.requestSubmit()
                } else {
                    this.form.submit()
                }
            }
        })

        this.input.addEventListener('keydown', ev => {
            if (ev.code && ev.code === 'Escape') {
                this.close()
            } else if (ev.keyCode && ev.keyCode === 27) {
                this.close()
            }
        })

        this.form.addEventListener('transitionend', ev => {
            if (this.isOpen()) {
                this.input.focus()
            }
        })
    }

    open() {
        this.form.classList.add('is-open')
    }

    close() {
        this.form.classList.remove('is-open')
    }

    isOpen() {
        return this.form.classList.contains('is-open')
    }

    isEmpty() {
        return this.input.value.trim() === ''
    }
}

export { SearchForm }
