import { CheckButton } from '../../atoms/button/button'

class OpeningHours {
    /**
     * @param element {HTMLElement | Element}
     */
    constructor(element) {
        /**
         * @private
         * @type {HTMLElement|Element}
         */
        this.element = element
        this.initializeElements()
        this.initializeEvents()
    }

    /**
     * @private
     */
    initializeElements() {
        /**
         * @private
         * @type {CheckButton}
         */
        this.daysOfWeekButton = new CheckButton(this.element.querySelector('.js-days-of-week'))
        this.daysOfWeekButton.setButtonText(this.buildDaysOfWeekText(this.daysOfWeekButton.getValues(), this.daysOfWeekButton.getLabels()))

        /**
         * @private
         * @type {HTMLElement | Element}
         */
        this.typeField = this.element.querySelector('.js-type')

        /**
         * @private
         * @type {NodeListOf<HTMLElement | Element> | HTMLElement[] | Element[]}
         */
        this.timeFields = this.element.querySelectorAll('.js-time')
    }

    /**
     * @private
     */
    initializeEvents() {
        this.daysOfWeekButton.addEventListener('optionSelected', () => {
            this.daysOfWeekButton.setButtonText(this.buildDaysOfWeekText(this.daysOfWeekButton.getValues(), this.daysOfWeekButton.getLabels()))
        })

        const toggleTimeFields = typeField => {
            if (typeField.value === 'appointment' || typeField.value === 'webshop' || typeField.value === 'closed-for-season') {
                this.timeFields.forEach(field => {
                    field.setAttribute('disabled', 'disabled')
                })
            } else {
                this.timeFields.forEach(field => {
                    field.removeAttribute('disabled')
                })
            }
        }
        this.typeField.addEventListener('change', ev => {
            toggleTimeFields(ev.currentTarget)
        })
        toggleTimeFields(this.typeField)
    }

    buildDaysOfWeekText(values, labels) {
        const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
        const textValues = []
        weekDays.forEach((weekDay, index) => {
            if (values.indexOf(weekDay) !== -1) {
                // If weekDay is in values
                if (values.indexOf(weekDays[index - 1]) !== -1 && values.indexOf(weekDays[index + 1]) !== -1) {
                    // Skip week days, where the previous and next day also are selected
                    if (textValues[textValues.length - 1] !== '-') {
                        // Add - only once
                        textValues.push('-')
                    }
                } else {
                    if (textValues.length > 0 && textValues[textValues.length - 1] !== '-') {
                        // Add comma, if there already are items
                        textValues.push(',')
                    }
                    // Push the actual week day short name
                    textValues.push(labels[weekDays.indexOf(weekDay)].substr(0, 3))
                }
            }
        })

        return textValues.join(' ')
    }
}

export { OpeningHours }
