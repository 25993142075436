import { ConsentCookie } from './consent-cookie'

/* global gtag */
class GoogleAnalytics {
    constructor() {
        this.consentCookie = new ConsentCookie()
    }

    /**
     * @param consent {boolean | object}
     */
    updateConsent(consent) {
        gtag('consent', 'update', {
            ad_storage: consent && consent.marketing ? 'granted' : 'denied',
            analytics_storage: consent && consent.statistic ? 'granted' : 'denied'
        })
    }
}

export { GoogleAnalytics }
