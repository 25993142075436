const merge = require('lodash.merge')

class Collapse {
    constructor(domElement, options = {}) {
        /**
         * @private
         * @type {HTMLElement}
         */
        this.element = domElement
        /**
         * @private
         * @type CollapseOptions
         */
        this.options = merge({
            elements: {
                toggle: '.js-collapse-toggle'
            },
            classes: {
                collapsed: 'is-collapsed',
                noAnimation: 'no-animation'
            },
            collapsed: true,
            collapsedHeight: '2rem'
        }, options)
        /**
         * @private
         * @type {EventTarget}
         */
        this.eventTarget = new EventTarget()

        /**
         * @private
         * @type {CollapseState}
         */
        this.state = {
            actualHeight: '0px'
        }

        this.initializeElements()
        this.initializeListeners()

        if (this.options.collapsed) {
            this.collapse(true)
        }
    }

    /**
     * @private
     */
    initializeElements() {
        this.calculateActualHeight()

        // Initialize the toggle buttons to make sure it is an array after all
        /**
         * @private
         * @type {HTMLElement[]|Node[]}
         */
        this.toggleButtons = []
        if (typeof this.options.elements.toggle === 'string') {
            this.toggleButtons = Array.from(this.element.querySelectorAll(this.options.elements.toggle))
        } else if (!(this.options.elements.toggle instanceof NodeList)) {
            this.toggleButtons = [this.options.elements.toggle]
        } else {
            this.toggleButtons = Array.from(this.options.elements.toggle)
        }
    }

    /**
     * @private
     */
    initializeListeners() {
        this.toggleButtons.forEach(button => {
            button.addEventListener('click', () => {
                this.toggle()
            })
        })

        window.addEventListener('load', () => {
            this.calculateActualHeight()
            if (this.isCollapsed()) {
                this.element.style.height = this.getCollapsedHeight()
            } else {
                this.element.style.height = this.state.actualHeight
            }
        })
    }

    calculateActualHeight() {
        const cssHeightValue = this.element.style.height
        this.element.classList.add(this.options.classes.noAnimation)
        this.element.style.height = 'auto'
        this.state.actualHeight = getComputedStyle(this.element).getPropertyValue('height')
        if (cssHeightValue) {
            this.element.style.height = cssHeightValue
        }
        this.element.classList.remove(this.options.classes.noAnimation)
    }

    getCollapsedHeight() {
        if (typeof this.options.collapsedHeight === 'function') {
            return this.options.collapsedHeight.call(this, this.element)
        } else {
            return this.options.collapsedHeight
        }
    }

    toggle() {
        if (this.isCollapsed()) {
            this.expand()
        } else {
            this.collapse()
        }
    }

    isCollapsed() {
        return this.element.classList.contains(this.options.classes.collapsed)
    }

    expand(disableAnimation = false) {
        if (disableAnimation) {
            this.element.classList.add(this.options.classes.noAnimation)
        }
        this.element.style.height = this.state.actualHeight
        this.element.classList.remove(this.options.classes.collapsed)
        if (disableAnimation) {
            this.element.classList.remove(this.options.classes.noAnimation)
        }
    }

    collapse(disableAnimation = false) {
        if (disableAnimation) {
            this.element.classList.add(this.options.classes.noAnimation)
        }
        this.element.style.height = this.getCollapsedHeight()
        this.element.classList.add(this.options.classes.collapsed)
        if (disableAnimation) {
            this.element.classList.remove(this.options.classes.noAnimation)
        }
    }
}

export { Collapse }
