import { OpeningHours } from './opening-hours'
import { Dialog } from '../../atoms/dialog/dialog'
import { DynamicField } from '../../molecules/form/dynamicField'

const merge = require('lodash.merge')

/* global google, texts */
/**
 * @implements EventTarget
 */
class Stall {
    /**
     * @param element {HTMLElement|Element}
     * @param options {StallOptions}
     */
    constructor(element, options = {}) {
        /**
         * @private
         * @type {HTMLElement|Element}
         */
        this.element = element

        /**
         * @private
         * @type StallOptions
         */
        this.options = merge({}, options)
        this.saveUrl = this.element.dataset.saveUrl
        this.deleteUrl = this.element.dataset.deleteUrl

        this.initializeElements()
        this.initializeEvents()
        this.initializeForm()
    }

    /**
     * @private
     */
    initializeElements() {
        /**
         * @private
         * @type {HTMLElement | Element}
         */
        this.saveButton = this.element.querySelector('.js-stall-save')
        /**
         * @private
         * @type {HTMLElement | Element}
         */
        this.deleteButton = this.element.querySelector('.js-stall-delete')
        /**
         * @private
         * @type {Dialog}
         */
        this.deleteDialog = new Dialog(this.element.querySelector('.js-delete-dialog'))
        /**
         * @private
         * @type {Dialog}
         */
        this.infoDialog = new Dialog(this.element.querySelector('.js-info-dialog'))
        /**
         * @private
         * @type {Dialog}
         */
        this.progressDialog = new Dialog(this.element.querySelector('.js-progress-dialog'))
    }

    /**
     * @private
     */
    initializeEvents() {
        /**
         * @private
         * @type {EventTarget}
         */
        this.eventTarget = new EventTarget()

        this.deleteButton.addEventListener('click', () => {
            this.deleteDialog.open()
        })

        this.deleteDialog.addEventListener('click', ev => {
            if (ev.detail.button === 'ok') {
                this.delete()
            }
        })

        this.element.addEventListener('submit', ev => {
            ev.preventDefault()
            this.save()
        })
    }

    /**
     * Initialize event listeners on form fields
     *
     * @private
     */
    initializeForm() {
        /**
         * @private
         * @type {NodeListOf<HTMLElement | Element> | HTMLElement[] | Element[]}
         */
        this.addressFields = this.element.querySelectorAll('[name*="address"], [name*="postalCode"], [name*="city"], [name*="country"]')
        let addressCheckTimeout = null
        this.addressFields.forEach(field => {
            ['change', 'keyup'].forEach(eventType => {
                field.addEventListener(eventType, () => {
                    if (addressCheckTimeout) {
                        window.clearTimeout(addressCheckTimeout)
                    }
                    addressCheckTimeout = window.setTimeout(this.checkAddressAndUpdateCoordinates.bind(this), 500)
                })
            })
        })

        /**
         * @private
         * @type {HTMLElement | Element}
         */
        this.latitudeField = this.element.querySelector('[name*="latitude"]')
        /**
         * @private
         * @type {HTMLElement | Element}
         */
        this.longitudeField = this.element.querySelector('[name*="longitude"]')

        this.element.querySelectorAll('.js-dynamic-field').forEach(element => {
            // eslint-disable-next-line no-new
            const dynamicField = new DynamicField(element)
            if (element.dataset.fieldName === 'openingHours') {
                dynamicField.addEventListener('rowAdded', ev => {
                    // eslint-disable-next-line no-new
                    new OpeningHours(ev.detail.row)
                })
                dynamicField.getRows().forEach(row => {
                    // eslint-disable-next-line no-new
                    new OpeningHours(row)
                })
            }
        })
    }

    checkAddressAndUpdateCoordinates() {
        let fieldsComplete = true
        const fieldsValues = {
            address: '',
            postalCode: '',
            city: '',
            country: ''
        }
        this.addressFields.forEach(fieldToCheck => {
            if (fieldToCheck.value.trim() !== '') {
                if (/\[?(\w+)]?$/.test(fieldToCheck.getAttribute('name'))) {
                    fieldsValues[RegExp.$1] = fieldToCheck.value.trim()
                }
            } else {
                fieldsComplete = false
            }
        })
        if (fieldsComplete) {
            const geoCoder = new google.maps.Geocoder()
            geoCoder.geocode({
                address: `${fieldsValues.address}, ${fieldsValues.postalCode} ${fieldsValues.city}, ${fieldsValues.country}`
            }, this.handleGeoCodingResult.bind(this))
        }
    }

    /**
     * @param results {google.maps.GeocoderResult[]}
     * @param status {string}
     */
    handleGeoCodingResult(results, status) {
        if (status === 'OK' && results.length > 0) {
            this.latitudeField.value = results[0].geometry.location.lat()
            this.longitudeField.value = results[0].geometry.location.lng()
            this.dispatchEvent(new Event('coordinatesUpdated'))
        }
    }

    save() {
        const data = new FormData(this.element)
        const progressDialogTimeout = setTimeout(() => {
            this.progressDialog.setMessage(texts.stall.save.progress)
            this.progressDialog.open()
        }, 300)
        fetch(this.saveUrl, {
            method: 'post',
            body: data,
            credentials: 'same-origin',
            cache: 'no-cache'
        }).then(response => {
            if (response.status === 200) {
                return response.json().then(json => {
                    // Clear eventual progress dialog
                    clearTimeout(progressDialogTimeout)
                    this.progressDialog.close()

                    // Clear all validation messages
                    this.element.querySelectorAll('.form__error').forEach(formError => {
                        formError.parentElement.classList.remove('has-error')
                        formError.remove()
                    })

                    if (json.status === 'ok') {
                        this.saveUrl = json.saveUrl
                        this.deleteUrl = json.deleteUrl

                        // Show saved indicator
                        this.saveButton.classList.add('is-saved')
                        setTimeout(() => {
                            this.saveButton.classList.remove('is-saved')
                        }, 2000)
                    } else {
                        Object.keys(json.errors).forEach(fieldName => {
                            const field = this.element.querySelector(`[name="${fieldName}"]`)
                            console.log(field)
                            field.parentElement.classList.add('has-error')
                            const errorElement = document.createElement('span')
                            errorElement.classList.add('form__error')
                            errorElement.innerText = json.errors[fieldName]
                            requestAnimationFrame(() => {
                                field.parentElement.append(errorElement)
                            })
                        })
                    }
                })
            } else {
                return response.text().then(responseText => {
                    // Try parsing response as JSON to find possible error message
                    let json
                    try {
                        json = JSON.parse(responseText)
                    } catch (e) {
                        json = {}
                    }
                    if (json.message) {
                        throw new Error(json.message)
                    } else {
                        throw new Error(texts.error.general)
                    }
                })
            }
        }).catch(error => {
            clearTimeout(progressDialogTimeout)
            this.progressDialog.close(true)
            this.infoDialog.setMessage(error.message)
            this.infoDialog.open()
        })
    }

    delete() {
        if (this.deleteUrl) {
            this.deleteDialog.close()
            const progressDialogTimeout = setTimeout(() => {
                this.progressDialog.setMessage(texts.stall.delete.progress)
                this.progressDialog.open()
            }, 300)
            fetch(this.deleteUrl, {
                method: 'post',
                credentials: 'same-origin',
                cache: 'no-cache'
            }).then(response => {
                if (response.status === 200) {
                    // Clear eventual progress dialog
                    clearTimeout(progressDialogTimeout)
                    this.progressDialog.close()

                    // Remove stall item
                    requestAnimationFrame(() => {
                        this.element.remove()
                        this.dispatchEvent(new Event('deleted'))
                    })
                } else {
                    return response.text().then(responseText => {
                        // Try parsing response as JSON to find possible error message
                        let json
                        try {
                            json = JSON.parse(responseText)
                        } catch (e) {
                            json = {}
                        }
                        if (json.message) {
                            throw new Error(json.message)
                        } else {
                            throw new Error(texts.error.general)
                        }
                    })
                }
            }).catch(error => {
                console.error(error)
                clearTimeout(progressDialogTimeout)
                this.progressDialog.close(true)
                this.infoDialog.setMessage(error.message)
                this.infoDialog.open()
            })
        } else {
            this.deleteDialog.close()
            this.element.remove()
            this.dispatchEvent(new Event('deleted'))
        }
    }

    addEventListener(type, listener, options = false) {
        return this.eventTarget.addEventListener(type, listener, options)
    }

    dispatchEvent(event) {
        return this.eventTarget.dispatchEvent(event)
    }

    removeEventListener(type, callback, options = false) {
        return this.eventTarget.removeEventListener(type, callback, options)
    }
}

export { Stall }
