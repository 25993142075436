import { Dialog } from '../../atoms/dialog/dialog'

class Table {
    /**
     *
     * @param {HTMLElement | Element} domElement
     */
    constructor(domElement) {
        this.element = domElement

        this.initializeDeleteDialog()
        this.initializeDeleteButtons()
    }

    initializeDeleteDialog() {
        this.deleteDialogElement = document.querySelector('.js-delete-dialog')
        this.deleteDialog = new Dialog(this.deleteDialogElement)
    }

    initializeDeleteButtons() {
        this.element.querySelectorAll('.js-delete-button').forEach(button => {
            button.addEventListener('click', ev => {
                const btn = ev.currentTarget
                this.deleteDialog.setMessage(btn.dataset.question)
                this.deleteDialogElement.dataset.url = btn.dataset.url
                this.deleteDialog.open()
            })
        })

        this.deleteDialog.addEventListener('click', ev => {
            if (ev.detail.button === 'ok') {
                window.location.href = this.deleteDialogElement.dataset.url
            } else {
                this.deleteDialog.close()
            }
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-table').forEach(tableElement => {
        // eslint-disable-next-line no-new
        new Table(tableElement)
    })
})
