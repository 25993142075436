import { Stall } from './stall'

/* global google */
class StallsView {
    /**
     * @param domElement {HTMLElement|Element}
     */
    constructor(domElement) {
        /**
         * @private
         * @type {HTMLElement|Element}
         */
        this.element = domElement
        this.initializeMap()
        this.initializeAddStallButton()
        /**
         * @private
         * @type {int|null}
         */
        this.maxRows = this.element.dataset.maxRows ? parseInt(this.element.dataset.maxRows) : null

        this.element.querySelectorAll('.js-stall').forEach(stallElement => {
            this.initializeStall(stallElement)
        })
        this.updateMarkersOnMap()
        this.updateAddButtonState()
    }

    initializeAddStallButton() {
        /**
         * @private
         * @type {HTMLElement | Element}
         */
        this.addStallButton = this.element.querySelector('.js-stall-add')
        const templateElement = this.element.querySelector('.js-template[data-field-name="stall"]')
        /**
         * @private
         * @type {string}
         */
        this.template = templateElement.innerHTML
        templateElement.remove()

        if (this.addStallButton && this.template) {
            this.addStallButton.addEventListener('click', () => {
                this.addStall()
            })
        }
    }

    initializeMap() {
        const mapElement = document.querySelector('.js-profile-map')
        if (mapElement) {
            /**
             * @type {google.maps.Marker[]}
             */
            this.markers = []
            /**
             * @type {google.maps.Map<Element>}
             */
            this.map = new google.maps.Map(mapElement, {
                maxZoom: 17
            })
            const defaultBoundaries = JSON.parse(mapElement.dataset.defaultBoundaries)
            this.map.fitBounds(new google.maps.LatLngBounds(defaultBoundaries.sw, defaultBoundaries.ne), 10)
        }
    }

    addStall() {
        const template = this.template.replace(/__stallNumber__/g, Math.random().toString(16).slice(2))
        const tmp = document.createElement('div')
        tmp.innerHTML = template
        const stallElement = this.addStallButton.parentElement.parentElement.insertBefore(tmp.firstElementChild, this.addStallButton.parentElement)
        this.initializeStall(stallElement)
        this.updateAddButtonState()
    }

    /**
     * Initialize events on the added stall element
     *
     * @param element {HTMLElement | Element}
     */
    initializeStall(element) {
        const stall = new Stall(element)

        stall.addEventListener('coordinatesUpdated', this.updateMarkersOnMap.bind(this))
        stall.addEventListener('deleted', this.updateMarkersOnMap.bind(this))
        stall.addEventListener('deleted', () => {
            this.updateAddButtonState()
        })
    }

    updateMarkersOnMap() {
        // Clear existing markers
        while (this.markers.length > 0) {
            const marker = this.markers.shift()
            marker.setMap(null)
        }

        const boundaries = new google.maps.LatLngBounds()

        this.element.querySelectorAll('.js-stall').forEach(stallElement => {
            const latField = stallElement.querySelector('[name*="latitude"]')
            const lngField = stallElement.querySelector('[name*="longitude"]')
            const lat = latField.value
            const lng = lngField.value
            if (lat.trim() !== '' && lng.trim() !== '') {
                const marker = new google.maps.Marker({
                    map: this.map,
                    position: new google.maps.LatLng(lat, lng),
                    draggable: true
                })
                marker.addListener('dragend', ev => {
                    latField.value = ev.latLng.lat()
                    lngField.value = ev.latLng.lng()
                })
                this.markers.push(marker)
                boundaries.extend(marker.getPosition())
            }
        })

        if (!boundaries.isEmpty()) {
            this.map.fitBounds(boundaries)
        }
    }

    updateAddButtonState() {
        if (this.maxRows && this.element.querySelectorAll('.js-stall').length >= this.maxRows) {
            this.addStallButton.classList.add('is-hidden')
        } else {
            this.addStallButton.classList.remove('is-hidden')
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    Promise.resolve().then(() => {
        const stallsViewElement = document.querySelector('.js-stalls-view')
        if (stallsViewElement) {
            new StallsView(stallsViewElement) // eslint-disable-line no-new
        }
    })
})
