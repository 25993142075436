class Message {
    /**
     * @param {HTMLElement} domElement
     */
    constructor(domElement) {
        /**
         * @type {HTMLElement | Element}
         */
        this.element = domElement

        this.initializeElements()
        this.initializeEvents()
    }

    initializeElements() {
        /**
         * @private
         * @type {HTMLElement | Element}
         */
        this.progress = this.element.querySelector('.js-progress')
        this.progress.classList.add('is-in-progress')
    }

    initializeEvents() {
        this.progress.addEventListener('animationend', () => {
            this.element.remove()
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-flash-message').forEach(message => {
        console.log(message)
        // eslint-disable-next-line no-new
        new Message(message)
    })
})
