class ConsentCookie {
    constructor() {
        this.consentCookieName = 'cookieConsent'

        /**
         * @private
         * @type {?object | boolean | null}
         */
        this.state = null

        this.initialize()
    }

    /**
     * Reads the consent cookie and updates the internal state property
     */
    initialize() {
        let consentCookie
        document.cookie.split('; ').forEach(function(cookie) {
            if (cookie.indexOf('cookieConsent=') === 0) {
                consentCookie = cookie
            }
        })
        if (consentCookie) {
            const cookie = consentCookie.split('=')
            try {
                const cookieValue = JSON.parse(cookie[1])
                if (cookieValue === 1) {
                    this.state = null
                } else {
                    this.state = cookieValue
                }
            } catch (e) {
            }
        }
    }

    isConsentGiven(consentType) {
        return this.state && this.state[consentType]
    }

    isConsentUnclear() {
        return this.state == null
    }

    /**
     * Set the consent response. Either `true` if given og `false` if denied.
     *
     * @param {object | boolean} state
     */
    setContent(state) {
        this.state = state
        document.cookie = `${this.consentCookieName}=${JSON.stringify(state)};path=/;max-age=31536000` // set consent cookie for one year
    }
}

export { ConsentCookie }
