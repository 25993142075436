import { DynamicField } from '../../molecules/form/dynamicField'

class MediaView {
    /**
     *
     * @param element {HTMLElement | Element}
     */
    constructor(element) {
        /**
         * @type {HTMLElement | Element}
         */
        this.element = element
        this.initializeDynamicFields()
    }

    initializeDeleteButton(button) {
        button.addEventListener('click', ev => {
            const formElement = document.querySelector(`#${ev.currentTarget.dataset.for}`)
            const matches = formElement.getAttribute('name').match(/description\[(.*?)]/)
            formElement.parentElement.remove()
            this.addDescriptionMultiButton.enableOption(`[data-value="${matches[1]}"]`)
        })
    }

    initializeDynamicFields() {
        this.element.querySelectorAll('.js-dynamic-field').forEach(field => {
            const dynamicField = new DynamicField(field)
            if (field.dataset.fieldName === 'gallery') {
                const initializeGalleryRow = row => {
                    const type = parseInt(row.querySelector('[name*="[type]"]').value)
                    if (type === 0) {
                        row.querySelector('[name*="[embed_url]"]').parentElement.style.display = 'none'
                    } else {
                        row.querySelector('[type="file"]').parentElement.style.display = 'none'
                    }
                }
                dynamicField.addEventListener('rowAdded', ev => {
                    const button = ev.detail.button
                    const row = ev.detail.row
                    if (button.dataset.type === 'image') {
                        row.querySelector('[name*="[type]"]').value = 0
                        row.querySelector('[name*="[embed_url]"]').parentElement.style.display = 'none'
                        const fileField = row.querySelector('[type="file"]')
                        fileField.addEventListener('change', () => {
                            if (fileField.files.length > 0) {
                                const preview = row.querySelector('.js-preview-image')
                                const reader = new FileReader()
                                reader.onload = ev => {
                                    preview.src = ev.target.result
                                }
                                reader.readAsDataURL(fileField.files[0])
                            }
                        })
                    } else {
                        row.querySelector('[name*="[type]"]').value = 1
                        row.querySelector('[type="file"]').parentElement.style.display = 'none'
                    }
                })
                dynamicField.getRows().forEach(row => {
                    initializeGalleryRow(row)
                })
            }
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    Promise.resolve().then(() => {
        const mediaViewElement = document.querySelector('.js-media-view')
        if (mediaViewElement) {
            new MediaView(mediaViewElement) // eslint-disable-line no-new
        }
    })
})
